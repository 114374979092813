@use '@angular/material' as mat;

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import '@angular/material/theming';

@import '../src/app/fav-icon-link/link-and-mark.theme';


$MES-theme-primary: mat.define-palette(mat.$blue-palette, 700, 400, A100);
$MES-theme-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$MES-theme: mat.define-light-theme($MES-theme-primary, $MES-theme-accent);


@include mat.all-legacy-component-themes($MES-theme);

@include link-and-mark-theme($MES-theme);

@import "src/styles_flex.scss";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@font-face {
  font-family: 'ArialNarrow';
  src: url('./assets/Fonts/ArialNarrow.ttf') format("truetype");
}

@font-face {
  font-family: 'ArialNarrow-bold';
  src: url('./assets/Fonts/ARIALNB.TTF') format("truetype");
}

@font-face {
  font-family: 'SegoeUI';
  src: url('./assets/Fonts/SegoeUI.ttf') format("truetype");  
}

html{
  scrollbar-width: none !important;
  -webkit-overflow-scrolling: touch !important; 
  ::-webkit-scrollbar {        
    background-color: transparent !important;
    display:none !important;
    width: 0px !important;
    height: 0px !important;
    -ms-overflow-style: none !important;
    -webkit-overflow-scrolling: touch !important;
    -webkit-appearance: none !important;
    z-index: -1;
  }
}

body{
    background-color: #F2F2F2;
    margin: unset !important;    
    overflow:scroll !important;  
    scrollbar-width: none !important;
    -webkit-overflow-scrolling: touch !important;
   
    ::-webkit-scrollbar {        
      background-color: transparent !important;
      display:none !important;
      width: 0px !important;
      height: 0px !important;
      -ms-overflow-style: none !important;
      -webkit-overflow-scrolling: touch !important;
      -webkit-appearance: none !important;
      z-index: -1;
    }  
  }  
  .Header{
    z-index: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .mat-drawer-content{
    background-color: #F2F2F2;
    z-index: auto !important;
  }

  .mat-sidenav-container {
    padding: 0 !important;
}
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px;
}