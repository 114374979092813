@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@mixin link-and-mark-theme($theme) {

  $fgColor: map-get($theme, foreground);
  $bgColor: map-get($theme, background);
  $accent: map-get($theme, accent);

  .lnm {
    font-size: 105%;
    color: mat.get-color-from-palette($accent, base);
    vertical-align: middle;
    display: inline-block;
    line-height: 1;

    &:hover {
      font-size: calc(110%);
      cursor: pointer;
      color: mat.get-color-from-palette($accent, darker);
      background-color:mat.get-color-from-palette($bgColor, base);
    }

    //font-size: calc(100%);
    .material-icons {
      font-size: calc(100%);
      margin: 0;
      padding: 0;
    }
  }
  .lnm.favorite {
    color: mat.get-color-from-palette($accent) !important;
  }
}
