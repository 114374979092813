// These are classes to replace the old @angular/flex-layout. These should not be used for new things, just meant as an easy way to transition.

// fxLayout="column"
.fx-column {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	flex: 1 1 100%;
}

// fxLayout="column" fxLayoutAlign="center center"
.fx-column-center-center {
	flex-direction: column;
	box-sizing: border-box;
	display: flex;
	place-content: center;
	align-items: center;
}


// Flex: ROW

// fxLayout="row"
.fx-row {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
}

// fxLayout="row wrap"
.fx-row-wrap {
	flex-flow: wrap;
	box-sizing: border-box;
	display: flex;
}

// fxLayout="row" fxLayoutAlign="center center"
.fx-row-center-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: center;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="space-around center"
.fx-row-space-around-center {
	flex-direction: row wrap;
	box-sizing: border-box;
	display: flex;
	place-content: center space-around;
	align-items: center;
}

// fxLayout="row" fxLayoutAlign="center"
.fx-row-center {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch center;
	align-items: stretch;
}

// fxLayout="row" fxLayoutAlign="end end"
.fx-row-end-end {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: flex-end;
	align-items: flex-end;
}

// fxLayout="row" fxLayoutAlign="end"
.fx-row-end {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	place-content: stretch flex-end;
	align-items: stretch;
}

// fxLayout="row wrap" fxLayoutAlign="end end"
.fx-row-wrap-end-end {
	flex-flow: wrap;
	box-sizing: border-box;
	display: flex;
	place-content: flex-end;
	align-items: flex-end;
}

// fxLayout="row wrap" or "column wrap"
.fx-wrap {
	flex-flow: wrap;
}

// fxLayoutGap="16px grid"
.fx-layoutgap-16-grid {
	margin: 0px -16px -16px 0px;
}

// fxFlex


// fxFlex="45"
.fx-flex-45 {
	flex: 1 1 100%;
	max-width: 45%;
}

// fxFlex="55"
.fx-flex-55 {
	flex: 1 1 100%;
	max-width: 55%;
}

// fxFlex="75"
.fx-flex-75 {
	flex: 1 1 100%;
	max-width: 75%;
}

// fxFlex="90"
.fx-flex-90 {
	flex: 1 1 100%;
	max-width: 90%;
}

// fxFlex="95"
.fx-flex-95 {
	flex: 1 1 100%;
	max-width: 95%;
}

// fxFlex="100"
.fx-flex-100 {
	flex: 1 1 100%;
	max-width: 100%;
}

// fxFlexOrder="2"
.fx-flexorder-2 {
	order: 2;
}

// fxFlexOrder="4"
.fx-flexorder-4 {
	order: 4;
}

// fxFlexOrder="5"
.fx-flexorder-5 {
	order: 5;
}

// fxFlexOrder="6"
.fx-flexorder-6 {
	order: 6;
}
